/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CRProductSelection, CRProductSelectionSerializer } from "./cr-product-selection"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRRatesAndFees, CRRatesAndFeesSerializer } from "./cr-rates-and-fees"
import { CRAddress, CRAddressSerializer } from "./cr-address"
import { W2Company, W2CompanySerializer } from "./w2/w2-company"
import { CROfficers, CROfficersSerializer } from "./cr-officers"
import { CRSelfEmployedContact, CRSelfEmployedContactSerializer } from "./cr-self-employed-contact"
import { CRPartnership, CRPartnershipSerializer } from "./cr-partnership"
import { CRStandardFees, CRStandardFeesSerializer } from "./cr-standard-fees"
import VerofyHelper from "@app/_helpers/verofy.helper"
import { SelectOption } from "./select-option"
import { BoardingAddition, BoardingAdditionSerializer } from "./boarding-addition"
import { VerofyLoyalty, VerofyLoyaltySerializer } from "@appModels/customerRegistartion/verofyLoyalty"
import { PricingTemplateTypeEnum } from "@app/enums/pricingTemplateTypeEnum"
import { OpeningHours, OpeninghoursSerializer } from "@appModels/opening-hours"
import { FileFormData } from "@appModels/fileData"
import { CRPartialResetSerializer, PartialApproval } from "@appModels/partial-approval"
import { PosPaymentSetting, PosPaymentSettingSerializer } from "@appModels/customerRegistartion/posPaymentSetting"

export class CRData {
    id: number = 0

    // Steps
    setTheBasicsActive?: boolean = true
    setTheBasicsFinished: boolean = false

    MCCAndTurnoverActive?: boolean = false
    MCCAndTurnoverFinished: boolean = false

    cardRatesAndFeesActive?: boolean = false
    cardRatesAndFeesFinished: boolean = false

    businessTypeActive?: boolean = false
    businessTypeFinished?: boolean = false

    addressesActive?: boolean = false
    addressesFinished: boolean = false

    customerContactActive?: boolean = false
    customerContactFinished: boolean = false

    setupCompleteActive?: boolean = false
    setupCompleteFinished: boolean = false

    totalCost?: number = 0
    totalOneOffCost?: number = 0

    // Set the basics
    contractLength?: number
    contractLengthByMonths?: number
    productsServicesSelection?: CRProductSelection[]
    standardFeesSelection?: CRStandardFees[]

    // MCC & Turnover
    mcc?: string
    estimatedTurnover?: string
    turnoverSplit?: number = 0
    estimatedAtv?: string
    possibleAtv?: number
    descritptionOfGoods?: string
    goodsOrServicesList?: {
        name: string
        percentage: number
    }[]
    estimatedTurnoverPeriod?: string = "yearly"
    cardPresentTransactions?: number = 0
    cardNotPresentTransactions?: number = 0
    merchantsOffer?: {
        serviceName: string
        servicePrice: number
    }[]
    openingHoursFrom?: string
    openingHoursTo?: string
    hasWeekendOpeningHours?: boolean
    weekendOpeningHoursFrom?: string
    weekendOpeningHoursTo?: string
    openingHours?: OpeningHours[]
    takeDeposit?: boolean
    upfrontPayments?: boolean
    seasonalPayments?: boolean
    extendedWarranty?: boolean
    thirdPartyServices?: boolean
    individualSales?: number
    despositAmountAvg?: number
    deliveryDepositDays?: number
    balanceDepositDays?: number
    upfrontPaymentDelivery?: number
    quaranteesWarrantyExplanation?: string
    warrantyProvidedBy?: string
    warrantyIncludedInPrice?: boolean
    warrantyMaxPeriodOffered?: string
    warrantyExtendedOffered?: boolean
    warrantyAvgCost?: string
    depositsBalancePayableBeforeDelivery?: boolean
    depositsBalanceTakenByCard?: boolean
    requiredBusinessDocuments?: FileFormData[] = []

    // Rates and fees
    selectedCardRates: CRRatesAndFees
    isAmexMidAvailable?: boolean = null

    // Business type
    companyType?: string
    tradingName?: string
    companyIdentificator?: string
    companyId?: string
    isTradingNameSameAsLegalName: boolean = null
    VATRegistered?: string = "0"
    VATnumber?: string = ""
    VATnumberIsValid?: boolean = false
    merchantStartedTrading?: string = ""

    businessTaken?: boolean
    previousEntityType?: boolean
    merchantBusinessAcquired?: string
    previousCompanyType?: string
    previousTradedFrom?: string
    previousTradedTo?: string
    previousOnlinePresence?: string

    fetchedCompany?: W2Company

    officers?: CROfficers[]
    originalOfficers?: CROfficers[]

    // Contact - Sole Trader / Self Employed
    selfEmployedContact?: CRSelfEmployedContact

    // Partnership / LP / LLP
    partnerShipContact?: CRPartnership

    // Addresses
    proofOfAddress: string
    locationOfSale: string

    hasWebsite: boolean = false
    website: string

    hardwareDeliverySameAsAddress: string

    address1: CRAddress
    address2: CRAddress
    hardwareDeliveryAddress: CRAddress | null

    showProofOfAddressArea: {
        address1: boolean
        address2: boolean
    }

    addressSectionUpdated: boolean = false
    hasValidGoogleAddress: boolean = false

    resetKYCVariables: {
        KYCResetIsActive: boolean
        availableTerms: {
            id: string
            value: boolean
        }[]
        selectedMessages: {
            id: string
            value: boolean
        }[]
        enableCNP: boolean
    }

    partialApprovalResetVariables?: PartialApproval

    boardingAdditions: BoardingAddition[]

    verofyLoyalty: VerofyLoyalty
    pricingTemplateType: PricingTemplateTypeEnum

    posPaymentSetting: PosPaymentSetting

    getDirectorsOnly() {
        if (this.officers) {
            // return this.officers.filter(officer => officer.isDirector && officer.isShareholder)
            return this.officers.filter(officer => officer.isDirector)
        } else {
            return this.officers
        }
    }

    getShareholdersOnly() {
        if (this.officers) {
            return this.officers.filter(officer => !officer.isDirector && officer.isShareholder)
        } else {
            return this.officers
        }
    }

    hasServicesSelected(): boolean {
        let serviceSelected = false
        this.standardFeesSelection.find(item => {
            if (item.feeQuantity > 0) {
                serviceSelected = true
            }
        })
        return serviceSelected
    }

    getEstimatedTurnover(currencyCode: string, turnoverPeriodOptions: SelectOption[]): string {
        let estimatedTurnoverValue: number = 0
        const estimatedTurnoverPeriodValue = turnoverPeriodOptions.find(item => item.id === "yearly")?.name

        if (this.estimatedTurnoverPeriod === "monthly") {
            estimatedTurnoverValue = Number(this.estimatedTurnover) * 12
        } else {
            estimatedTurnoverValue = Number(this.estimatedTurnover)
        }

        return VerofyHelper.formatCurrency(estimatedTurnoverValue, currencyCode) + " " + estimatedTurnoverPeriodValue
    }
}

@Injectable({
    providedIn: "root",
})
export class CRDataSerializer implements ApiResourceSerializer {
    constructor(
        private customerRegistrationAddressSerializer: CRAddressSerializer,
        private cRRatesAndFeesSerializer: CRRatesAndFeesSerializer,
        private cRProductsAndServicesSerializer: CRProductSelectionSerializer,
        private cROfficersSerializer: CROfficersSerializer,
        private cRSelfEmployedContactSerializer: CRSelfEmployedContactSerializer,
        private cRPartnerShipContactSerializer: CRPartnershipSerializer,
        private w2CompanySerializer: W2CompanySerializer,
        private crStandardFeesSerializer: CRStandardFeesSerializer,
        private boardingAdditionSerializer: BoardingAdditionSerializer,
        private verofyLoyaltySerializer: VerofyLoyaltySerializer,
        private openinghoursSerializer: OpeninghoursSerializer,
        private crPartialResetSerializer: CRPartialResetSerializer,
        private posPaymentSettingSerializer: PosPaymentSettingSerializer,
    ) {}

    fromJson(json: JsonObject): CRData {
        const data = new CRData()

        // Steps
        data.setTheBasicsActive = json.set_the_basics_active
        data.setTheBasicsFinished = json.set_the_basics_finished
        data.MCCAndTurnoverActive = json.mcc_and_turnover_active
        data.MCCAndTurnoverFinished = json.mcc_and_turnover_finished
        data.cardRatesAndFeesActive = json.card_rates_and_fees_active
        data.cardRatesAndFeesFinished = json.card_rates_and_fees_finished
        data.businessTypeActive = json.business_type_active
        data.businessTypeFinished = json.business_type_finished
        data.addressesActive = json.addresses_active
        data.addressesFinished = json.addresses_finished
        data.customerContactActive = json.customer_contact_active
        data.customerContactFinished = json.customer_contact_finished
        data.setupCompleteActive = json.setup_complete_active
        data.setupCompleteFinished = json.setup_complete_finished

        data.totalCost = json.total_cost !== undefined ? json.total_cost : 0
        data.totalOneOffCost = json.total_one_off_cost !== undefined ? json.total_one_off_cost : 0

        // Set the basics
        data.contractLength = json.contract_length
        data.contractLengthByMonths = json.contract_length_by_months
        data.productsServicesSelection = json.products_services_selection
            ? json.products_services_selection.map(item => this.cRProductsAndServicesSerializer.fromJson(item))
            : null

        data.standardFeesSelection =
            json.standard_fees_selection !== undefined && json.standard_fees_selection !== null
                ? json.standard_fees_selection.map(item => this.crStandardFeesSerializer.fromJson(item))
                : []

        data.selectedCardRates =
            json.selected_card_rates !== undefined && json.selected_card_rates !== null
                ? this.cRRatesAndFeesSerializer.fromJson(json.selected_card_rates)
                : null

        // MCC & Turnover
        data.mcc = json.mcc
        data.estimatedTurnover = json.estimated_turnover
        data.turnoverSplit = json.turnover_split
        data.estimatedAtv = json.estimated_atv
        data.possibleAtv = json.possible_atv
        data.descritptionOfGoods = json.description_of_goods

        data.openingHoursFrom = json.open_from
        data.openingHoursTo = json.open_to
        data.hasWeekendOpeningHours = json.has_weekend_opening_hours
        data.weekendOpeningHoursFrom = json.weekend_opening_hours_from
        data.weekendOpeningHoursTo = json.weekend_opening_hours_to
        data.cardPresentTransactions = json.card_present_transactions
        data.cardNotPresentTransactions = json.card_not_present_transactions

        data.isAmexMidAvailable = json.is_amex_mid_available === undefined ? null : json.is_amex_mid_available

        // Boarding additions
        data.businessTaken = json.recently_taken_over_business
        data.previousEntityType = json.traded_under_different_entity
        data.merchantBusinessAcquired = json.business_acquired_at
        data.previousCompanyType = json.traded_under_different_entity_type
        data.previousTradedFrom = json.traded_under_different_entity_from
        data.previousTradedTo = json.traded_under_different_entity_to
        data.previousOnlinePresence = json.previous_online_presence
        data.takeDeposit = json.deposits
        data.upfrontPayments = json.upfront_payments
        data.seasonalPayments = json.seasonal_payments
        data.extendedWarranty = json.guarantees_warranty
        data.thirdPartyServices = json.third_party
        data.individualSales = json.deposits_individual_sales
        data.despositAmountAvg = json.deposits_average
        data.deliveryDepositDays = json.deposits_days_between_delivery
        data.balanceDepositDays = json.deposits_days_between_balance_payment
        data.upfrontPaymentDelivery = json.upfront_payments_days_between_delivery
        data.locationOfSale = json.location_of_sale
        data.goodsOrServicesList = json.goods_or_services_list
        data.quaranteesWarrantyExplanation = json.quarantees_warranty_explanation
        data.warrantyProvidedBy = json.quarantees_warranty_provided_by
        data.warrantyIncludedInPrice = json.quarantees_warranty_included_in_price
        data.warrantyMaxPeriodOffered = json.quarantees_warranty_max_period_offered
        data.warrantyExtendedOffered = json.quarantees_warranty_extended_offered
        data.warrantyAvgCost = json.quarantees_warranty_avg_cost
        data.depositsBalancePayableBeforeDelivery = json.deposits_is_balance_payable_before_delivery
        data.depositsBalanceTakenByCard = json.deposits_is_balance_taken_by_card
        data.requiredBusinessDocuments = json.required_business_documents

        // Business type
        data.companyType = json.company_type
        data.tradingName = json.trading_name
        data.companyIdentificator = json.company_identificator
        data.companyId = json.company_id
        data.VATRegistered = json.vat_registered
        data.isTradingNameSameAsLegalName = json.is_trading_name_same_as_legal_name ?? null
        data.VATnumber = json.vat_number
        data.VATnumberIsValid = json.vat_number_is_valid
        data.merchantStartedTrading = json.merchant_started_trading

        // Fetched company
        data.fetchedCompany =
            json.fetched_company !== undefined && json.fetched_company !== null
                ? this.w2CompanySerializer.fromJson(json.fetched_company)
                : null

        data.officers = json.officers ? json.officers.map(item => this.cROfficersSerializer.fromJson(item)) : undefined

        data.originalOfficers =
            json.original_officers !== undefined && json.original_officers !== null
                ? json.original_officers.map(item => this.cROfficersSerializer.fromJson(item))
                : undefined

        // Contact - Sole Trader / Self Employed
        data.selfEmployedContact =
            json.self_employed_contact !== undefined && json.self_employed_contact !== null
                ? this.cRSelfEmployedContactSerializer.fromJson(json.self_employed_contact)
                : undefined

        // Contact - Partnership
        data.partnerShipContact =
            json.partnership_contact !== undefined && json.partnership_contact !== null
                ? this.cRPartnerShipContactSerializer.fromJson(json.partnership_contact)
                : undefined

        // Addresses
        data.proofOfAddress = json.proof_of_address
        data.hasWebsite = json.has_website
        data.website = json.website
        data.hardwareDeliverySameAsAddress = json.hardware_delivery_same_as_address

        data.address1 =
            json.address_1 !== undefined && json.address_1 !== null
                ? this.customerRegistrationAddressSerializer.fromJson(json.address_1)
                : null

        data.address2 =
            json.address_2 !== undefined && json.address_2 !== null
                ? this.customerRegistrationAddressSerializer.fromJson(json.address_2)
                : null

        data.hardwareDeliveryAddress =
            json.hardware_delivery_address !== undefined && json.hardware_delivery_address !== null
                ? this.customerRegistrationAddressSerializer.fromJson(json.hardware_delivery_address)
                : null

        data.showProofOfAddressArea = json.show_proof_of_address_area
            ? {
                  address1: json.show_proof_of_address_area.address_1,
                  address2: json.show_proof_of_address_area.address_2,
              }
            : {
                  address1: false,
                  address2: false,
              }

        // We do not save the valua from the server
        // data.addressSectionUpdated = json.address_section_updated

        data.hasValidGoogleAddress = json.has_valid_google_address

        data.resetKYCVariables = json.reset_kyc_variables
            ? {
                  KYCResetIsActive: json.reset_kyc_variables.kyc_reset_is_active,
                  availableTerms: json.reset_kyc_variables.available_terms,
                  selectedMessages: json.reset_kyc_variables.selected_messages,
                  enableCNP: json.reset_kyc_variables.enable_cnp,
              }
            : {
                  KYCResetIsActive: false,
                  availableTerms: [],
                  selectedMessages: [],
                  enableCNP: true,
              }

        data.partialApprovalResetVariables = json.partial_approval_reset_variables
            ? this.crPartialResetSerializer.fromJson(json.partial_approval_reset_variables)
            : null

        data.boardingAdditions = json.boarding_additions
            ? json.boarding_additions.map(item => {
                  return this.boardingAdditionSerializer.fromJson(item)
              })
            : []
        data.openingHours = json.opening_hours
            ? json.opening_hours.map(item => {
                  return this.openinghoursSerializer.fromJson(item)
              })
            : []
        data.verofyLoyalty = json.verofy_loyalty ? this.verofyLoyaltySerializer.fromJson(json.verofy_loyalty) : null
        data.pricingTemplateType = json.pricing_template_type
            ? json.pricing_template_type
            : PricingTemplateTypeEnum.STANDARD
        data.posPaymentSetting = json.pos_payment_setting
            ? this.posPaymentSettingSerializer.fromJson(json.pos_payment_setting)
            : null
        return data
    }

    toJson(data: CRData): object {
        return {
            set_the_basics_active: data.setTheBasicsActive,
            set_the_basics_finished: data.setTheBasicsFinished,
            mcc_and_turnover_active: data.MCCAndTurnoverActive,
            mcc_and_turnover_finished: data.MCCAndTurnoverFinished,
            card_rates_and_fees_active: data.cardRatesAndFeesActive,
            card_rates_and_fees_finished: data.cardRatesAndFeesFinished,
            business_type_active: data.businessTypeActive,
            business_type_finished: data.businessTypeFinished,
            addresses_active: data.addressesActive,
            addresses_finished: data.addressesFinished,
            customer_contact_active: data.customerContactActive,
            customer_contact_finished: data.customerContactFinished,
            setup_complete_active: data.setupCompleteActive,
            setup_complete_finished: data.setupCompleteFinished,

            contract_length: data.contractLength,
            contract_length_by_months: data.contractLengthByMonths,
            total_cost: data.totalCost,
            total_one_off_cost: data.totalOneOffCost,

            products_services_selection: data.productsServicesSelection
                ? data.productsServicesSelection.map(item => this.cRProductsAndServicesSerializer.toJson(item))
                : [],

            standard_fees_selection: data.standardFeesSelection
                ? data.standardFeesSelection.map(item => this.crStandardFeesSerializer.toJson(item))
                : [],

            selected_card_rates:
                data.selectedCardRates !== undefined && data.selectedCardRates != null
                    ? this.cRRatesAndFeesSerializer.toJson(data.selectedCardRates)
                    : null,

            mcc: data.mcc,
            estimated_turnover: data.estimatedTurnover,
            turnover_split: data.turnoverSplit,
            estimated_atv: data.estimatedAtv,
            possible_atv: data.possibleAtv,
            description_of_goods: data.descritptionOfGoods,

            opening_hours_from: data.openingHoursFrom,
            opening_hours_to: data.openingHoursTo,
            has_weekend_opening_hours: data.hasWeekendOpeningHours,
            weekend_opening_hours_from: data.weekendOpeningHoursFrom,
            weekend_opening_hours_to: data.weekendOpeningHoursTo,
            estimated_turnover_period: data.estimatedTurnoverPeriod,
            card_present_transactions: data.cardPresentTransactions,
            card_not_present_transactions: data.cardNotPresentTransactions,

            is_amex_mid_available: data.isAmexMidAvailable,

            recently_taken_over_business: data.businessTaken,
            traded_under_different_entity: data.previousEntityType,
            business_acquired_at: data.merchantBusinessAcquired,
            traded_under_different_entity_type: data.previousCompanyType,
            traded_under_different_entity_from: data.previousTradedFrom,
            traded_under_different_entity_to: data.previousTradedTo,
            previous_online_presence: data.previousOnlinePresence,
            deposits: data.takeDeposit,
            upfront_payments: data.upfrontPayments,
            seasonal_payments: data.seasonalPayments,
            guarantees_warranty: data.extendedWarranty,
            third_party: data.thirdPartyServices,
            deposits_individual_sales: data.individualSales,
            deposits_average: data.despositAmountAvg,
            deposits_days_between_delivery: data.deliveryDepositDays,
            deposits_days_between_balance_payment: data.balanceDepositDays,
            upfront_payments_days_between_delivery: data.upfrontPaymentDelivery,
            location_of_sale: data.locationOfSale,
            goods_or_services_list: data.goodsOrServicesList,
            quarantees_warranty_explanation: data.quaranteesWarrantyExplanation,
            quarantees_warranty_provided_by: data.warrantyProvidedBy,
            quarantees_warranty_included_in_price: data.warrantyIncludedInPrice,
            quarantees_warranty_max_period_offered: data.warrantyMaxPeriodOffered,
            quarantees_warranty_extended_offered: data.warrantyExtendedOffered,
            quarantees_warranty_avg_cost: data.warrantyAvgCost,
            deposits_is_balance_payable_before_delivery: data.depositsBalancePayableBeforeDelivery,
            deposits_is_balance_taken_by_card: data.depositsBalanceTakenByCard,
            required_business_documents: data.requiredBusinessDocuments,

            company_type: data.companyType,
            trading_name: data.tradingName,
            company_identificator: data.companyIdentificator,
            company_id: data.companyId,
            vat_registered: data.VATRegistered,
            is_trading_name_same_as_legal_name: data.isTradingNameSameAsLegalName,
            vat_number: data.VATnumber,
            vat_number_is_valid: data.VATnumberIsValid,
            merchant_started_trading: data.merchantStartedTrading,

            fetched_company:
                data.fetchedCompany !== undefined && data.fetchedCompany != null
                    ? this.w2CompanySerializer.toJson(data.fetchedCompany)
                    : null,

            officers: data.officers ? data.officers.map(item => this.cROfficersSerializer.toJson(item)) : undefined,

            original_officers: data.originalOfficers
                ? data.originalOfficers.map(item => this.cROfficersSerializer.toJson(item))
                : undefined,

            self_employed_contact:
                data.selfEmployedContact !== undefined && data.selfEmployedContact != null
                    ? this.cRSelfEmployedContactSerializer.toJson(data.selfEmployedContact)
                    : null,

            partnership_contact:
                data.partnerShipContact !== undefined && data.partnerShipContact != null
                    ? this.cRPartnerShipContactSerializer.toJson(data.partnerShipContact)
                    : undefined,

            proof_of_address: data.proofOfAddress,

            has_website: data.hasWebsite,
            website: data.website,

            hardware_delivery_same_as_address: data.hardwareDeliverySameAsAddress,

            address_1:
                data.address1 !== undefined && data.address1 != null
                    ? this.customerRegistrationAddressSerializer.toJson(data.address1)
                    : null,

            address_2:
                data.address2 !== undefined && data.address2 != null
                    ? this.customerRegistrationAddressSerializer.toJson(data.address2)
                    : null,

            hardware_delivery_address: data.hardwareDeliveryAddress
                ? this.customerRegistrationAddressSerializer.toJson(data.hardwareDeliveryAddress)
                : null,

            show_proof_of_address_area: data.showProofOfAddressArea
                ? {
                      address_1: data.showProofOfAddressArea.address1,
                      address_2: data.showProofOfAddressArea.address2,
                  }
                : {
                      address_1: false,
                      address_2: false,
                  },

            // We only save the value when we send it to the server
            address_section_updated: data.addressSectionUpdated,

            has_valid_google_address: data.hasValidGoogleAddress,

            reset_kyc_variables: data.resetKYCVariables
                ? {
                      kyc_reset_is_active: data.resetKYCVariables.KYCResetIsActive,
                      available_terms: data.resetKYCVariables.availableTerms,
                      selected_messages: data.resetKYCVariables.selectedMessages,
                      enable_cnp: data.resetKYCVariables.enableCNP,
                  }
                : null,
            boarding_additions: data.boardingAdditions
                ? data.boardingAdditions.map(item => {
                      return this.boardingAdditionSerializer.toJson(item)
                  })
                : [],
            verofy_loyalty: this.verofyLoyaltySerializer.toJson(data.verofyLoyalty),
            // partial_approval_reset_variables: this.crPartialSerializer.toJson(data.partialApprovalResetVariables)
            partial_approval_reset_variables: data.partialApprovalResetVariables
                ? this.crPartialResetSerializer.toJson(data.partialApprovalResetVariables)
                : null,
            pricing_template_type: data.pricingTemplateType,
            opening_hours: data.openingHours
                ? data.openingHours.map(item => {
                      return this.openinghoursSerializer.toJson(item)
                  })
                : [],
            pos_payment_setting: this.posPaymentSettingSerializer.toJson(data.posPaymentSetting),
        }
    }
}
