// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    storageVersion: "1",
    production: false,
    disableConsoleLog: false,
    isSoftwareUpdateEnabled: false,
    mainApiUrl: "https://dev-main-service.verofy.com/api/v1/partner-portal/",
    mainApi: {
        url: "https://dev-main-service.verofy.com/api/v1/partner-portal/",
        endpoints: {
            customerPreRegistrations: "customer-registration",
            products: "products",
            customers: "customers",
            applications: "applications",
            quotes: "quotes",
            cardRates: "card-rates",
            partners: "partners",
            sellers: "sellers",
            passwordReset: "password-reset",
            widgets: "widgets",
        },
    },
    customerRegistrationApi: {
        url: "https://dev-main-service.verofy.com/api/v1/customer-registration/",
        endpoints: {
            products: "products",
            productPacks: "product-packs",
            productsWithPacks: "products-with-packs",
            posProductsWithPacks: "pos-products-with-packs",
            productCategories: "product-categories",
            cardRates: "card-rates",
            cardRatesWithFees: "card-rates-with-fees",
            midFees: "mid-fees",
            standardFees: "standard-fees",
            companies: "companies",
            boardingAdditions: "boarding-additions",
            companiesReport: "companies/report",
        },
        virtualPaymentsFeeContract: true,
    },
    partnerRegistrationApi: {
        url: "https://dev-main-service.verofy.com/api/v1/partner-registration/",
        endpoints: {
            preRegistrations: "customer-registration",
        },
    },
    utilitiesApi: {
        url: "https://dev-main-service.verofy.com/api/v1/utilities/",
        endpoints: {},
    },
    mainApiLogin: "login",
    mainApiLogout: "logout",
    mainApiEnums: "enums",
    mainApiValidation: "validation",

    customerPortalUrl: "https://dev-customer-portal.verofy.com",

    mainApiAccountSecurityOneTouch: "account-security/onetouch",
    mainApiAccountSecurityOneTouchStatus: "account-security/onetouch-status",
    mainApiAccountSecuritySms: "account-security/sms",
    mainApiAccountSecurityVerify: "account-security/verify",

    encryptionisActive: true,
    encryptKey: "M72UZc8p4nHQqBoj3XIC1Q", // 22 length
    encryptIV: "zpg3BBnVMlv63hMnO3In3w", // 22 length

    enableSentry: false,
    allowXdebugHttpSession: false,
    sentryEnvironment: "development",
    sentryTracingOrigins: ["development", "https://dev-main-service.verofy.com"], // Case sensitive!
    tracesSampleRate: 1.0, // While you're testing, set tracesSampleRate to 1.0, as that ensures that every transaction will be sent to Sentry.

    googleApiKey: "AIzaSyDJMw-nVWng2o9qAsDGRxUMa0hJIsOS4As",

    addressLookUpURL: "https://ws.postcoder.com/pcw/",
    addressLookupApiKey: "PCWH9-TJPWW-ZDX2L-WD8GP",
}
