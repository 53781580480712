import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { PriceRegularity } from "@appModels/price-regularity";

export class PosPaymentType {
    public static UPFRONT_COST = "upfront-cost"
    public static MONTHLY_COST = "monthly-cost"
    public static FINANCE = "finance-cost"

    id: string
    name: string
    priceRegularity: number

    static getRegularity(id: string): number {
        if (id === PosPaymentType.UPFRONT_COST) {
            return PriceRegularity.ONE_OFF
        }

        return PriceRegularity.MONTHLY
    }
}

@Injectable({
    providedIn: "root",
})
export class PosPaymentTypeSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): PosPaymentType {
        const type = new PosPaymentType()
        type.id = json.id
        type.name = json.name
        type.priceRegularity = json.price_regularity

        return type
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(type: PosPaymentType): any {
        return {
            id: type.id,
            name: type.name,
            price_regularity: type.priceRegularity,
        }
    }
}
