import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "@appCore/api-resource.serializer"
import { JsonObject } from "@appCore/types"

export class PosPaymentSetting {
    id: number
    commercialSetup: string
    upfrontPaymentType: string
    monthlyPaymentType: string
    hasPackagePricing: boolean
    packageOneOffCost: number
    packageMonthlyCost: number
    isPackageOneOffCostFree: boolean
    isPackageMonthlyCostFree: boolean
    paymentCollection: string
    freeMonths: number
}

@Injectable({
    providedIn: "root",
})
export class PosPaymentSettingSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): PosPaymentSetting {
        const record = new PosPaymentSetting()
        record.id = 0

        record.commercialSetup = json.commercial_setup ? json.commercial_setup: null
        record.upfrontPaymentType = json.upfront_payment_type ? json.upfront_payment_type : null
        record.monthlyPaymentType = json.monthly_payment_type ? json.monthly_payment_type: null
        record.hasPackagePricing = json.has_package_pricing ? json.has_package_pricing : false
        record.packageOneOffCost = json.package_one_off_cost ? json.package_one_off_cost : null
        record.packageMonthlyCost = json.package_monthly_cost ? json.package_monthly_cost : null
        record.isPackageOneOffCostFree = json.is_package_one_off_cost_free ? json.is_package_one_off_cost_free : false
        record.isPackageMonthlyCostFree = json.is_package_monthly_cost_free ? json.is_package_monthly_cost_free : false
        record.paymentCollection = json.payment_collection ? json.payment_collection : null
        record.freeMonths = json.free_months ? json.free_months : 0
        return record
    }

    toJson(record: PosPaymentSetting): JsonObject {
        if (record === null) {
            return {}
        }
        return {
            id: record.id, // Including ID if necessary
            commercial_setup: record.commercialSetup,
            upfront_payment_type: record.upfrontPaymentType,
            monthly_payment_type: record.monthlyPaymentType,
            has_package_pricing: record.hasPackagePricing,
            package_one_off_cost: record.packageOneOffCost,
            package_monthly_cost: record.packageMonthlyCost,
            is_package_one_off_cost_free: record.isPackageOneOffCostFree,
            is_package_monthly_cost_free: record.isPackageMonthlyCostFree,
            payment_collection: record.paymentCollection,
            free_months: record.freeMonths,
        }
    }
}
