<h4 mat-dialog-title class="dialogTitle bgColor">{{ data.title }}
  <p class="close-icon" (click)="onClose()">
    <svg-close></svg-close>
  </p>
</h4>

<div mat-dialog-content class="message-content">{{ data.message }}</div>
<div mat-dialog-actions class="dJustifyCenter">
  <button mat-button (click)="onClose()" class="custom-button">Close</button>
</div>
